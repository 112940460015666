export function processExperiments(experiments) {
  // Helper function to get month name from date string
  const getMonthName = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  // Helper function to get the number of days in a month
  const getDaysInMonth = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let days = 0;

    if (startDate.getMonth() === endDate.getMonth()) {
      days = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
    } else {
      days =
        (new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0) -
          startDate) /
          (1000 * 60 * 60 * 24) +
        1;
      days +=
        (endDate - new Date(endDate.getFullYear(), endDate.getMonth(), 1)) /
          (1000 * 60 * 60 * 24) +
        1;
    }

    return days;
  };

  // Step 1: Initialize data structure
  const monthsData = {};

  // Step 2: Process each experiment
  experiments?.forEach((exp) => {
    const startDate = new Date(exp.period.start);
    const endDate = new Date(exp.period.end);
    const startMonth = getMonthName(exp.period.start);
    const endMonth = getMonthName(exp.period.end);

    // Determine the primary month based on days of participation
    const daysInStartMonth = getDaysInMonth(
      exp.period.start,
      new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
    );
    const daysInEndMonth = getDaysInMonth(
      new Date(endDate.getFullYear(), endDate.getMonth(), 1),
      exp.period.end
    );

    let primaryMonth = startMonth;
    if (daysInEndMonth > daysInStartMonth) {
      primaryMonth = endMonth;
    }

    if (!monthsData[primaryMonth]) {
      monthsData[primaryMonth] = {};
    }

    // Initialize store alias data
    if (!monthsData[primaryMonth][exp.store.alias]) {
      monthsData[primaryMonth][exp.store.alias] = 0;
    }

    // Accumulate the delta totalSold for the store alias in the primary month
    monthsData[primaryMonth][exp.store.alias] +=
      exp?.status?.totalSold - exp?.problem?.totalSold;
  });

  // Step 3: Format the result
  const result = Object.entries(monthsData).map(([month, stores]) => ({
    month,
    total: Object.values(stores).reduce((sum, value) => sum + value, 0),
    ...stores,
    date: new Date(month), // Agregar una fecha para ordenar
  }));

  // Ordenar el resultado por fecha
  result.sort((a, b) => a.date - b.date);

  // Eliminar el campo de fecha antes de devolver
  result.forEach((item) => delete item.date);

  return result;
}

const tooltipRenderer = ({ datum, xKey, yKey }) => {
  return { content: `${datum[xKey]}: €${datum[yKey].toFixed(2)}` };
};

//se le debe pasar el resultado de la funcion processExperiments
export function createChartSeries(data) {
  // Inicializar el arreglo para almacenar los resultados
  const series = [];

  // Crear un conjunto para almacenar los store.alias únicos
  const storeAliases = new Set();

  // Si el arreglo de datos no está vacío, procesar los objetos
  if (data.length > 0) {
    // Crear la serie para el total mensual
    series.push({
      type: "bar",
      xKey: "month",
      yKey: "total",
      yName: "Total",
      grouped: true,
      tooltip: {
        renderer: tooltipRenderer,
      },
      fill: "#663b73",
    });

    // Iterar sobre cada objeto en el arreglo de datos
    data.forEach((item) => {
      // Iterar sobre las claves de cada objeto
      Object.keys(item).forEach((key) => {
        if (key !== "month" && key !== "total") {
          // Añadir el alias al conjunto para asegurar que sea único
          storeAliases.add(key);
        }
      });
    });

    // Crear una serie para cada store.alias único
    storeAliases.forEach((alias) => {
      series.push({
        type: "line",
        xKey: "month",
        yKey: alias,
        yName: alias,
        tooltip: {
          renderer: tooltipRenderer,
        },
        marker: {
          itemStyler: () => {
            return { size: 10 };
          },
        },
      });
    });
  }

  return series;
}

//se le debe pasar el resultado de la funcion createChartSeries
export function extractYKeys(arr) {
  // Inicializar el set para almacenar los yKeys únicos
  const yKeysSet = new Set();

  // Recorrer el arreglo de objetos
  arr.forEach((item) => {
    // Verificar si el objeto tiene una propiedad yKey y agregarla al set
    if (item.yKey) {
      yKeysSet.add(item.yKey);
    }
  });

  // Convertir el set a un arreglo y agregar "total" al inicio
  const result = ["total", ...Array.from(yKeysSet)];

  return result;
}

export function calculateIncrementals(experiments) {
  const getDaysInMonth = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let days = 0;

    if (startDate.getMonth() === endDate.getMonth()) {
      days = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
    } else {
      days =
        (new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0) -
          startDate) /
          (1000 * 60 * 60 * 24) +
        1;
      days +=
        (endDate - new Date(endDate.getFullYear(), endDate.getMonth(), 1)) /
          (1000 * 60 * 60 * 24) +
        1;
    }

    return days;
  };
  const monthsData = {};

  experiments?.forEach((exp) => {
    const startDate = new Date(exp.period.start);
    const endDate = new Date(exp.period.end);
    const startMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    }).format(startDate);
    const endMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    }).format(endDate);
    const deltaRevenue = exp?.status?.totalSold - exp?.problem?.totalSold;
    const deltaUnits = exp?.status?.unitsSold - exp?.problem?.unitsSold;

    // Determine the primary month based on days of participation
    const daysInStartMonth = getDaysInMonth(
      exp.period.start,
      new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
    );
    const daysInEndMonth = getDaysInMonth(
      new Date(endDate.getFullYear(), endDate.getMonth(), 1),
      exp.period.end
    );

    let primaryMonth = startMonth;
    if (daysInEndMonth > daysInStartMonth) {
      primaryMonth = endMonth;
    }

    if (!monthsData[primaryMonth]) {
      monthsData[primaryMonth] = { totalRevenue: 0, totalUnits: 0 };
    }

    monthsData[primaryMonth].totalRevenue += deltaRevenue;
    monthsData[primaryMonth].totalUnits += deltaUnits;
  });

  const incrementalRevenue = Object.values(monthsData)
    .reduce((sum, data) => sum + data.totalRevenue, 0)
    .toFixed(0);
  const incrementalUnits = Object.values(monthsData)
    .reduce((sum, data) => sum + data.totalUnits, 0)
    .toFixed(0);

  return {
    incrementalRevenue,
    incrementalUnits,
  };
}
